<template>
  <div class="top_news_wrap">
    <div class="page_news_wrap">
      <div
        v-for="newsItem in newsData"
        :key="newsItem.id"
        class="page_news_item"
        >
        <div class="page_news_item_body">
          <div class="page_news_item_date">{{ formatDate(new Date(newsItem.date)) }}</div>
          <div
            v-for="tag in newsItem.tags"
            :key="tag.id"
            class="page_news_item_tag"
            >{{ getTagById(tag).name }}</div>
          <div class="page_news_item_link">
            <a :href="newsItem.link" class="">{{ newsItem.title.rendered }}</a>
          </div>
        </div>
        <div class="page_news_item_arrow">
          <img src="../../assets/images/common/arrow.png">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  name: 'TopNewsList',
  data() {
    return {
      lang: 2,
      newsIds: "",
      tags: [],
      newsData: []
    }
  },
  async mounted() {
    let element = document.getElementById('topNewsList');
    let tagmasterNewsId = element.dataset.tagmasterNewsId;
    const response = await axios.get('/wp-json/wp/v2/news', {
      params: {
        language: element.dataset.lang,
        order: 'desc',
        per_page: 1
      }
    })
    this.newsData = response.data
    await this.setTags(tagmasterNewsId)
  },
  computed: {
  },
  methods: {
    async setTags(postId) {
      const response = await axios.get('/wp-json/wp/v2/tags?post=' + postId)
      this.tags = response.data
    },
    getTagById(tagId) {
      let tag = this.tags.find(tag => tag.id == tagId)
      if(tag) return tag
      else return {}
    },
    formatDate(dt) {
      var y = dt.getFullYear();
      var m = ('00' + (dt.getMonth()+1)).slice(-2);
      var d = ('00' + dt.getDate()).slice(-2);
      return (y + '.' + m + '.' + d);
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/global.scss';
$dk_blue: #0D1F6A;
$black: #212121;
* {
  box-sizing: border-box;
}
.top_news_wrap {
  border-top: 1px solid $black;
}
.page_news {
  &_wrap {
    width: 100%;
  }
  &_item {
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
    height: 75px;
    width: 100%;
    padding: 0 5%;
    border-bottom: 1px solid $gray;
    transition: .3s;
    @include sp() {
      height: 75px;
      padding: 0;
    }
    &:hover {
      padding: 0 4% 0 5%;
      @include sp() {
        padding: 0 4% 0 0;
      }
    }
  }
  &_item_date {
    color: $gray_dark;
    font: normal normal normal 16px/24px Noto Sans JP;
    padding-right: 13px;
    @include sp() {
      display: inline-block;
      font: normal normal normal 14px/20px Noto Sans JP;
    }
  }
  &_item_tag {
    display: block;
    color: white;
    padding: 0 10px;
    margin-right: 20px;
    font: normal normal normal 12px/26px Noto Sans JP;
    background: $black 0% 0% no-repeat padding-box;
    @include sp() {
      display: inline-block;
      font: normal normal normal 11px/20px Noto Sans JP;
    }
  }
  &_item_link {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    @include sp() {
      display: inline-block;
    }
  }
  &_item_body {
    width: 90%;
    display: flex;
    font: normal normal 500 16px/24px Noto Sans JP;
    @include sp() {
      display: block;
      font: normal normal 500 13px/19px Noto Sans JP;
    }
    a {
      color: $black;
    }
  }
  &_item_arrow {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
  }
	&_item_bottom {
    width: 90%;
  }
}
</style>
