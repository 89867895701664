<template>
  <div class="team_member_portfolio_wrap" v-if="domActive">

    <div class="team_member_portfolio_inner_wrap">
      <div class="team_member_portfolio_title">PORTFOLIO <span class="team_member_portfolio_title_sub">実績紹介</span></div>
      <div v-for="item in portfolioData" :key="item.id" class="team_member_portfolio_item">
        <a :href="item.link">
          <div style="overflow: hidden;">
            <div class="team_member_portfolio_item_image" :style="{backgroundImage: 'url(' + item.thumbnail_url + ')' }"></div>
          </div>
          <div class="team_member_portfolio_item_title">
            {{ item.title.rendered }}
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'TeamMemberPortfolio',
  data() {
    return {
      domActive: true,
      postIds: "",
      portfolioData: []
    }
  },
  async mounted() {
    let element = document.getElementById('teamMemberPortfolio');
    this.postIds = element.dataset.postIds
    if (this.postIds.length == 0) {
      this.domActive = false
      return
    }
    const response = await axios.get('/wp-json/wp/v2/portfolio?include=' + this.postIds.replace(' ', ''), {
      params: {
        per_page: 30
      }
    })
    this.portfolioData = response.data
    console.log(this.portfolioData);
  },
  computed: {
  },
  methods: {
    optionFilter(list, option, optionKey) {
      if (option.length == 0) return list
      else
        return list.filter((item) => {
          return option.indexOf(item[optionKey]) >= 0
        })
    },
    keyFilter(list, option, listKey) {
      if (option == 0) return list
      else
        return list.filter((item) => {
          return item[listKey].indexOf(option) >= 0
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
$dk_blue: #0D1F6A;
$black: #212121;

* {
  box-sizing: border-box;
}
.team_member_portfolio {
  &_wrap {
    background: transparent linear-gradient(65deg, rgba(209, 192, 214, 0.19) 0%, rgba(155, 184, 244, 0.19) 100%) 0% 0% no-repeat padding-box;
    padding: 70px 0 40px;
  }
  &_inner_wrap {
    width: 1000px;
    max-width: 100%;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (max-width: 1000px) {
      width: 90%;
    }
  }

  &_title {
    font: normal normal 600 24px/29px Cormorant Garamond;
    letter-spacing: 2.4px;
    color: #22154E;
    width: 100%;
    margin-bottom: 32px;
    &_sub {
      font: normal normal 500 14px/20px Noto Sans JP;
    }
  }

  &_item {
    width: 300px;
    margin-bottom: 40px;
    animation: fadeIn .5s ease 00s 1 normal;
    @media screen and (max-width: 1000px) {
      width: 90%;
      a {
        display: flex;
        justify-content: left;
        align-items: center;
      }
    }
    &_image {
      width: 300px;
      height: 200px;
      border: 1px solid #EAEAEA;
      background-color: #FFFFFF;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      transition: all .3s;
      @media screen and (max-width: 1000px) {
        width: 168px;
        height: 90px;
        margin-right: 16px;
      }
    &:hover {
        transform: scale(1.05)
      }
    }

    &_title {
      padding-top: 8px;
      font: normal normal bold 16px/24px Noto Sans JP;
      color: $black;
      @media screen and (max-width: 1000px) {
        max-width: calc(100% - 184px);
      }
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
</style>
