<template>
  <div class="top_team_wrap">
    <div class="top_team_inner_wrap">
      <div class="top_team_item_wrap" :style="slideWrapStyle()" id="topTeamItemWrap">
        <div
          v-for="item in teamData"
          :key="item.id"
          class="team_member_list_item"
          >
          <a :href="item.link">
            <div class="team_member_list_item_image_wrap">
              <div class="team_member_list_item_image" :style="{backgroundImage: 'url(' + item.thumbnail_url + ')' }"></div>
              <div class="team_member_list_item_image_readmore">READ MORE</div>
            </div>
            <div class="team_member_list_item_text_wrap">
              <div class="team_member_list_item_title">{{ item.title.rendered }} <span class="team_member_list_item_sub_title">{{ item.team_sub_name }}</span></div>
              <div class="team_member_list_item_group" v-if="item.team_group !== 'none'">
                {{ item.team_group }}
              </div>
              <div class="team_member_list_item_desc">
                {{ item.team_position }}
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="top_team_bottom_wrap only_pc">
      <div class="top_team_slide_button" @click="moveSlide(1, true)">
        <img src="../../assets/images/common/arrow_zh.png">
      </div>
      <div class="top_team_slide_button" @click="moveSlide(-1, true)">
        <img src="../../assets/images/common/arrow_zl.png">
      </div>
    </div>
    <div class="top_team_bottom_wrap only_sp">
      <div class="top_team_slide_button" @click="moveSlide(1, false)">
        <img src="../../assets/images/common/arrow_zh.png">
      </div>
      <div class="top_team_slide_button" @click="moveSlide(-1, false)">
        <img src="../../assets/images/common/arrow_zl.png">
      </div>
    </div>
    <div class="top_team_bottom_link_wrap only_sp">
      <a :href="lang === '2' ? 'team' : 'team-en'" class="lead_link_with_arrow">TEAM ALL<span class="arrow"></span></a>
    </div>
  </div>
</template>
<script>
import { shuffle, getWpResponseAll } from '@/modules/utils.js'
export default {
  name: 'TopTeamList',
  data() {
    return {
      windowWidth: window.innerWidth,
      teamIds: "",
      slidePosition: 0,
      slideWidth: 100,
      slideCount: 1,
      teamData: [],
      isPC: true
    }
  },
  async mounted() {
    let element = document.getElementById('topTeamList');
    this.lang = element.dataset.lang
    this.teamIds = element.dataset.teamIds
    this.teamData = await getWpResponseAll('/wp-json/wp/v2/team', {language: this.lang})
    this.teamData = shuffle(this.teamData)
    this.slideCount = this.teamData.length
    console.log(this.lang);
    if(this.windowWidth < 1000) {
      this.slidePosition = -90
    }
    setInterval(() => {
      if(this.windowWidth < 1000) {
        this.moveSlide(-1, false)
      } else {
        this.moveSlide(-1, true)
      }
    }, 4000);
  },
  computed: {
  },
  methods: {
    moveSlide(direction, isPC) {
      let wrapWidth = document.getElementById('topTeamItemWrap').clientWidth
      this.slideWidth = wrapWidth / this.slideCount
      if (direction > 0) {
        if (isPC && (this.slidePosition > -this.slideWidth)) {
          this.slidePosition = -wrapWidth + this.slideWidth * 3
        } else if (!isPC && (this.slidePosition > 0)) {
          this.slidePosition = -wrapWidth + this.slideWidth * 2 - this.slideWidth / 2
        } else {
          this.slidePosition += this.slideWidth
        }
      } else if (direction < 0) {
        if (isPC && (this.slidePosition < -wrapWidth + this.slideWidth * 4)) {
          this.slidePosition = 0
        } else if (!isPC && (this.slidePosition < -wrapWidth + this.slideWidth * 2)) {
          this.slidePosition = this.slideWidth - this.slideWidth / 2
        } else {
          this.slidePosition -= this.slideWidth
        }
      } else {
        this.slidePosition = this.slideWidth
      }
    },
    slideWrapStyle() {
      let style = {
        'transform': 'translateX(' + this.slidePosition + 'px)'
      }
      return style
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/global.scss';
$dk_blue: #0D1F6A;
$black: #212121;
* {
  box-sizing: border-box;
}
.team_member_list {
  &_item {
    width: 300px;
    padding: 0 50px;
    border-right: 1px solid $gray;
    animation: fadeIn .5s ease 00s 1 normal;
    @include sp() {
      width: 180px;
      padding: 0 20px;
    }
    &_image {
      &_wrap {
        width: 212px;
        height: 318px;
        border: 1px solid #EAEAEA;
        display: flex;
        align-items: end;
        justify-content: right;
        position: relative;
        @media screen and (max-width: 1000px) {
          width: 140px;
          height: 210px;
          margin-right: 16px;
        }
      }
      width: 212px;
      height: 318px;
      border: 1px solid #EAEAEA;
      background-color: #FFFFFF;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      @include sp() {
        width: 140px;
        height: 210px;
      }
      &_readmore {
        bottom: 0;
        right: 0;
        color: white;
        padding: 0 23px 20px 0;
        font-size: 12px;
        position: absolute;
      }
    }
    &_text_wrap {
      max-width: 100%;
    }
    &_title {
      font: normal normal bold 20px/29px Noto Sans JP;
      padding-top: 30px;
      color: $black;
    }
    &_sub_title {
      font: normal normal normal 14px/20px Noto Sans JP;
      color: $gray_light_1;
      white-space: nowrap;
    }
    &_group {
      font: normal 500 medium 14px/32px Noto Sans JP;
      letter-spacing: 0px;
      color: #0D1F6A;
    }
    &_desc {
      font: normal normal 500 13px/19px Noto Sans JP;
      white-space: pre-wrap;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
.top_team_item_wrap {
  display: flex;
  width: fit-content;
  // transform: translateX(-20px);
  transition: .3s;
}
.top_team_inner_wrap {
  display: flex;
  width: 100%;
  max-width: 899px;
  margin: 0 auto;
  overflow: hidden;
  @include sp() {
    max-width: 100%;
    width: 335px;
  }
}
.top_team_bottom_wrap {
  display: flex;
  justify-content: space-between;
  width: 216px;
  margin: 0 auto;
  align-items: center;
  height: 100px;
}
.top_team_bottom_link_wrap {
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.top_team_bottom_wrap {
  .top_team_slide_button {
    width: 102px;
    height: 36px;
    border: 1px solid #212121;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
