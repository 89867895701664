<template>
  <aside class="sidebar_links_root" id="sidebarLinksWrap">
  <div :class="{
               'sidebar_links_wrap': true
       }">
    <div
      v-for="(linkItem, i) in ankerList"
      :key="linkItem.id"
      :class="{
              'sidebar_links_inner_wrap': true,
              'is_sub_inner': ['H3', 'H4'].includes(linkItem.nodeName)
              }"
      >
      <div
        :class="{
                'sidebar_links_item': true,
                'active': getActiveAnker() === linkItem.id,
                'is_sub': ['H3', 'H4'].includes(linkItem.nodeName)
                }"
        @click="scrollToAnker(linkItem.id)"
        >
        <span
          class="sidebar_links_item_bar"
          v-if="['H3', 'H4'].includes(linkItem.nodeName)"
          >―</span>
        {{ linkItem.innerText }}
      </div>
      <div
        :key="innerLinkItem.id"
        class="only_sp sidebar_links_item sidebar_links_item_sub"
        v-for="innerLinkItem in getInnerAnkerList(i)"
        @click="scrollToAnker(innerLinkItem.id)"
        >
        <span
          class="sidebar_links_item_bar"
          >―</span>
        {{ innerLinkItem.innerText }}
      </div>
    </div>
  </div>
  </aside>
</template>
<script>
import VueScrollTo from 'vue-scrollto'
const CONTENTS_CLASS = ".contents_wrap"
export default {
  name: 'SidebarLinks',
  data() {
    return {
      scrollY: 0,
      ankerList: []
    }
  },
  async mounted() {
    window.addEventListener('scroll', this.handleScroll)
    for(let i = 0; i < 5; i++) {
      this.ankerList = this.getAnkerList()
      await this.delay(500)
    }
  },
  computed: {
  },
  methods: {
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    getAnkerList() {
      return Array.from(document.querySelectorAll(CONTENTS_CLASS + ' h2, ' + CONTENTS_CLASS + ' h3, ' + CONTENTS_CLASS + ' h4'))
    },
    getInnerAnkerList(startIndex) {
      let endIndex = this.ankerList.findIndex((item, i) => {
        return i > startIndex && item.nodeName === "H2"
      })
      if(startIndex >= endIndex) {
        endIndex = this.ankerList.length
      }
      return this.ankerList.slice(startIndex + 1, endIndex)
    },
    scrollToAnker(anker) {
      VueScrollTo.scrollTo('#' + anker, 600, {offset: -65, cancelable: true})
    },
    handleScroll() {
      this.scrollY = window.scrollY
    },
    getActiveAnker() {
      let node = this.ankerList.slice().reverse().find(item => {
        if (this.scrollY > 0) {
          return item.getBoundingClientRect().top < 120
        } else return false
      })
      if(node) return node.id
      else return 'none'
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/global.scss';
$header_height: 70px;
* {
  box-sizing: border-box;
}
.sidebar_links_root {
  height: 100%;
  max-width: 200px;
  @media screen and (max-width: 1000px) {
    max-width: 100%;
  }
}
.sidebar_links {
  &_wrap {
    transition: .4s ease;
    padding-top: 50px;
    width: 100%;
    max-width: 200px;
    top: $header_height;
    position: sticky;
    @media screen and (max-width: 1000px) {
      display: flex;
      max-width: 100%;
      justify-content: space-around;
      flex-wrap: wrap;
    }
  }
  &_inner_wrap {
    @media screen and (max-width: 1000px) {
      max-width: 50%;
    }
    &.is_sub_inner {
      @media screen and (max-width: 1000px) {
        display: none;
      }
    }
  }
  &_item {
    font: normal normal 500 16px/20px Cormorant Garamond;
    color: #B9B9B9;
    padding-left: 8px;
    margin-bottom: 16px;
    cursor: pointer;
    @media screen and (max-width: 1000px) {
      width: 157px;
      font: normal normal 600 15px/18px Cormorant Garamond;
      border-bottom: 1px solid $gray;
      position: relative;
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      &:after {
        content: "";
        display: block;
        line-height: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        bottom: -2px;
        width: 16px;
        border-bottom: 2px solid $dk_blue;
        @media screen and (max-width: 1000px) {
          bottom: -1px;
          border-bottom: 1px solid $dk_blue;
        }
      }
    }
    &.active {
      color: $dk_blue;
      border-left: 3px solid $dk_blue;
    }
    &.is_sub {
      padding-left: 20px;
      @media screen and (max-width: 1000px) {
        border: none;
        display: none;
        &:after {
          display: none;
        }
      }
    }
    &_sub {
      border: none;
      padding-left: 0;
      margin-bottom: 8px;
      &:after {
        display: none;
      }
    }
    &_bar {
      display: inline-block;
      margin-right: 10px;
    }
  }
}
</style>
