<template>
  <div class="news_list_root">
    <div class="page_news_search_wrap">
      <div class="page_news_search_item">
        <div class="page_news_search_title">YEAR</div>
        <select class="page_news_search_select" v-model="currentYear" @change="setAPIAndNum(1)">
          <option :value="0">ALL</option>
          <option v-for="year in selectYears" :value="year" :key="year">{{ year }}</option>
        </select>
      </div>
      <div class="only_pc page_news_search_border">
      </div>
      <div class="page_news_search_item">
        <div class="page_news_search_title">CATEGORY</div>
        <select class="page_news_search_select" v-model="currentTag" @change="setAPIAndNum(1)">
          <option :value="0">ALL</option>
          <option v-for="tag in tags" :value="tag.id" :key="tag.id">{{ tag.name }}</option>
        </select>
      </div>
    </div>
    <div class="page_news_wrap">
      <div
        v-for="newsItem in newsList"
        :key="newsItem.id"
        class="page_news_item"
        >
        <div class="page_news_item_body">
          <div class="page_news_item_top">
            <div class="page_news_item_date">{{ formatDate(new Date(newsItem.date)) }}</div>
            <div
              v-for="tag in newsItem.tags"
              :key="tag.id"
              class="page_news_item_tag"
              :href="getTagById(tag).link"
              >{{ getTagById(tag).name }}</div>
          </div>
          <div class="page_news_item_bottom">
            <a :href="newsItem.link" class="">{{ newsItem.title.rendered }}</a>
          </div>
        </div>
        <div class="page_news_item_arrow">
          <img src="../../assets/images/common/arrow.png">
        </div>
      </div>
    </div>
    <div class="pagination_wrap">
      <pagination
        :current="currentPage"
        :maximum="maximum"
        @clickItem="clickPaginate"
      >
      </pagination>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import Pagination from '../molecules/PaginationList';
const BASE_YEAR = 2006
export default {
  name: 'NewsList',
  components: {
    Pagination
  },
  data() {
    return {
      newsList: [],
      tagList: [],
      perPage: 10,
      currentPage: 1,
      maximum: 1,
      selectYears: [],
      tags: [],
      currentTag: 0,
      currentYear: 0,
      lang: 2
    }
  },
  async mounted() {
    this.lang = document.getElementById('newsList').dataset.lang;
    let tagmasterNewsId = document.getElementById('newsList').dataset.tagmasterNewsId;
    let today = new Date()
    let currentYear = today.getFullYear()
    this.selectYears = [...Array(currentYear - BASE_YEAR + 1)].map((_, i) => currentYear - i)
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    if(Number(params.get("num")) > 1) this.currentPage = Number(params.get("num"))
    await this.setAPIResponse(this.currentPage)
    await this.setTags(tagmasterNewsId)
  },
  computed: {
  },
  methods: {
    async setTags(postId) {
      const response = await axios.get('/wp-json/wp/v2/tags?post=' + postId)
      this.tags = response.data
    },
    async setAPIAndNum(num) {
      this.currentPage = num
      this.setUrlParam("num", num)
      await this.setAPIResponse(this.currentPage)
    },
    async setAPIResponse(num) {
      let params = {
        language: this.lang,
        page: num,
        per_page: this.perPage
      }
      if (this.currentTag > 0) {
        params.tags = this.currentTag
      }
      if (this.currentYear > 0) {
        params.before = this.currentYear + "-12-31T23:59:59"
        params.after = this.currentYear + "-01-01T00:00:00"
      }
      const response = await axios.get('/wp-json/wp/v2/news', {
        params: params
      })
      if (Number(response.headers['x-wp-totalpages']) >= 0) this.maximum = Number(response.headers['x-wp-totalpages'])
      this.newsList = response.data
    },
    async clickPaginate(num) {
      await this.setAPIResponse(num)
      this.currentPage = num
      this.setUrlParam("num", num)
    },
    setUrlParam(key, value) {
      const url = new URL(location)
      url.searchParams.set(key, value)
      history.pushState('', '', url.href);
    },
    getTagById(tagId) {
      let tag = this.tags.find(tag => tag.id == tagId)
      if(tag) return tag
      else return {}
    },
    formatDate(dt) {
      var y = dt.getFullYear();
      var m = ('00' + (dt.getMonth()+1)).slice(-2);
      var d = ('00' + dt.getDate()).slice(-2);
      return (y + '.' + m + '.' + d);
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/global.scss';
.news_list_root {
  width: 1000px;
  max-width: 100%;
  margin: 0 auto 40px;
}
.page_news_search {
  &_wrap {
    width: 100%;
    height: 100px;
    background: transparent linear-gradient(85deg, rgba(209, 192, 214, 0.19) 0%, rgba(155, 184, 244, 0.19) 100%) 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 0;
    @include sp() {
      height: fit-content
    }
  }
  &_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    color: $bk_blue;
    @include sp() {
      width: 90%;
      margin: 10px 0;
    }
    &:after {
      content: '';
      background-image: url('../../assets/images/common/news_select_icon.png');
      background-size: contain;
      background-repeat: no-repeat;
      display: block;
      height: 6px;
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translatey(-50%);
      width: 9px;
    }
  }
  &_title {
    @include sp() {
      width: 30%;
    }
  }
  &_select {
    margin-left: 10px;
    font: normal normal normal 16px/35px Noto Sans JP;
    padding: 0 10px;
    color: $gray_dark_2;
    border: 0;
    width: 220px;
    height: 42px;
    background-color: $white;
    appearance: none;
    @include sp() {
      width: 70%;
    }
  }
  &_border {
    height: 100%;
    width: 2px;
    background-color: white;
  }
}
.page_news {
  &_wrap {
    width: 1000px;
    max-width: 100%;
  }
  &_item {
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
    height: 175px;
    width: 100%;
    padding: 0 5%;
    border-bottom: 1px solid $gray;
    transition: .3s;
    @include sp() {
      height: 110px;
    }
    &:hover {
      padding: 0 4% 0 5%;
    }
  }
  &_item_date {
    color: $gray_dark;
    font: normal normal normal 16px/24px Noto Sans JP;
  }
  &_item_body {
    width: 80%;
  }
  &_item_arrow {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
  }
  &_item_top {
    padding-bottom: 12px;
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
  }
  &_item_tag {
    display: block;
    color: $white;
    padding: 2px 10px;
    margin-left: 20px;
    font: normal normal normal 12px/26px Noto Sans JP;
    background: $black 0% 0% no-repeat padding-box;
  }
	&_item_bottom {
    width: 90%;
    font: normal normal bold 16px/24px Noto Sans JP;
    a {
      color: $black;
    }
  }
}
.pagination {
  &_wrap {
  }
}
</style>
