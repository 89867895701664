import { createApp } from 'vue'
import PortfolioList from './components/organisms/PortfolioList'
import TeamMemberPortfolio from './components/organisms/TeamMemberPortfolio'
import TeamMemberList from './components/organisms/TeamMemberList'
import Header from './components/organisms/Header'
import NewsList from './components/organisms/NewsList'
import TopNewsList from './components/organisms/TopNewsList'
import TopTeamList from './components/organisms/TopTeamList'
import TopPortfolioList from './components/organisms/TopPortfolioList'
import SidebarLinks from './components/molecules/SidebarLinks'

createApp(SidebarLinks).mount('#sidebarLinks')
createApp(PortfolioList).mount('#portfolioList')
createApp(TeamMemberPortfolio).mount('#teamMemberPortfolio')
createApp(TeamMemberList).mount('#teamMemberList')
createApp(Header).mount('#header')
createApp(NewsList).mount('#newsList')
createApp(TopNewsList).mount('#topNewsList')
createApp(TopTeamList).mount('#topTeamList')
createApp(TopPortfolioList).mount('#topPortfolioList')
