<template>
  <div class="top_portfolio_wrap">
  <div class="top_portfolio_inner_wrap">
    <carousel :wrap-around="true" :itemsToShow="1.001" :autoplay="4000">
      <slide
        v-for="(item, index) in portfolioData"
        :key="item.id"
        >
        <div class="top_portfolio_item">
        <div class="top_portfolio_item_content">
          <div class="top_portfolio_item_index">
            PORTFOLIO 0{{ index + 1 }}
          </div>
          <div class="top_portfolio_item_image_wrap only_sp">
            <div class="top_portfolio_item_image" :style="{'backgroundImage': 'url(' + item.thumbnail_url + ')'}"></div>
            <div class="top_portfolio_item_image_title" v-html="item.title.rendered.replace('株式会社', '<span>株式会社</span>')">
            </div>
          </div>
          <div class="top_portfolio_item_title">
            {{ item.portfolio_title }}
          </div>
          <div class="top_portfolio_item_subtitle">
            {{ item.portfolio_subtitle_for_top }}
          </div>
          <div
            class="top_portfolio_item_desc"
            v-for="desc in item.portfolio_description_for_top.split('\n')"
            :key="desc"
            >
            <span>{{ desc }}</span>
          </div>
        </div>
        <div class="top_portfolio_item_image_wrap">
          <div class="top_portfolio_item_image only_pc" :style="{'backgroundImage': 'url(' + item.thumbnail_url + ')'}"></div>
          <div class="top_portfolio_item_image_title only_pc" v-html="item.title.rendered.replace('株式会社', '<span>株式会社</span>')">
          </div>
          <div class="top_portfolio_item_image_button">
            <a :href="item.link"><div class="button_base button_base_little">READ MORE</div></a>
          </div>
        </div>
        </div>
      </slide>
      <template #addons>
        <pagination />
      </template>
    </carousel>
  </div>
  </div>
</template>

<script>
import { shuffle, getWpResponseAll, getLangSlugByLangNum } from '@/modules/utils.js'
import axios from 'axios'
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

export default {
  name: 'TopPortfolioList',
  components: {
    Carousel,
    Slide,
    Pagination
  },
  data() {
    return {
      domActive: true,
      postIds: "",
      portfolioData: []
    }
  },
  async mounted() {
    let element = document.getElementById('topPortfolioList');
    this.lang = element.dataset.lang
    let langStr = getLangSlugByLangNum(element.dataset.lang)
    const categoryResponse = await axios.get('/wp-json/wp/v2/categories?slug=' + element.dataset.categoryslug + '&lang=' + langStr)
    const casestudyId = categoryResponse.data[0].id
    this.portfolioData = await getWpResponseAll('/wp-json/wp/v2/portfolio?categories=' + casestudyId, {language: this.lang})
    this.portfolioData = shuffle(this.portfolioData)
  },
  computed: {},
  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/global.scss';
$dk_blue: #0D1F6A;
$black: #212121;
* {
  box-sizing: border-box;
}
.top_portfolio_wrap {
  width: 100%;
  overflow: hidden;
}
.top_portfolio_inner_wrap {
  margin: 0 auto;
  width: 1060px;
  max-width: 100%;
  @include sp() {
    width: 345px;
  }
}
.top_portfolio_item {
  border: 1px solid $gray;
  width: 1000px;
  max-width: 100%;
  margin-bottom: 16px;
  padding: 40px 40px 60px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  @include sp() {
    justify-content: space-around;
    width: 335px;
    padding: 15px;
  }
  &_content {
    width: 480px;
    max-width: 100%;
  }
  &_index {
    border: 1px solid #B9B9B9;
    width: 130px;
    font: normal normal normal 13px/36px Noto Serif JP;
    color: $gray_dark;
    text-align: center;
    @include sp() {
      width: 120px;
    }
  }
  &_title {
    font: normal normal bold 22px/32px Noto Sans JP;
    letter-spacing: 1.32px;
    color: #212121;
    margin: 21px 0;
    @include sp() {
      font: normal normal bold 19px/28px Noto Sans JP;
      letter-spacing: 1.14px;
    }
  }
  &_subtitle {
    font: normal normal 500 16px/35px Noto Sans JP;
    letter-spacing: 0.96px;
    color: #212121;
    margin-bottom: 10px;
    @include sp() {
      font: normal normal 500 14px/24px Noto Sans JP;
      letter-spacing: 0.84px;
    }
  }
  &_desc {
    font: normal normal normal 14px/19px Noto Sans JP;
    letter-spacing: 0.84px;
    border-bottom: 1px solid $gray;
    display: flex;
    justify-content: left;
    padding: 13px 0;
    align-items: center;
    min-height: 36px;
    max-width: 100%;
    &:before {
      content: '';
      display: inline-block;
      width: 6px;
      height: 1px;
      margin-right: 8px;
      background-color: #212121;
    }
  }
  &_image_wrap {
    max-width: 360px;
    width: 100%;
    @include sp() {
      margin: 10px auto;
    }
  }
  &_image {
    width: 100%;
    height: 103px;
    background-size: contain;;
    background-repeat: no-repeat;
    background-position: center;
  }
  &_image_title {
    font: normal normal bold 22px/35px Noto Sans JP;
    letter-spacing: 1.32px;
    color: #212121;
    text-align: center;
    padding-top: 10px;
    @include sp() {
      font: normal normal 500 16px/18px Noto Sans JP;
      letter-spacing: 0.96px;
    }
    ::v-deep span {
      display: inline-block;
    }
  }
  &_image_button {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
}
.top_portfolio_wrap ::v-deep {
  .carousel__viewport {
    overflow: unset;
  }
  .carousel__pagination-button {
    width: 10px;
    height: 10px;
    background-color: #EAEAEA;
  }
  .carousel__pagination-button--active {
    width: 10px;
    height: 10px;
    background-color: #22154E;
  }
}
</style>
