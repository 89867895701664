<template>
  <div class="header_inner_wrap">
    <a :href="links.top"><div class="header_logo"></div></a>
    <div class="header_inner_wrap_right">
      <div class="header_menu_wrap">
        <a
          :href="headerItem.url"
          :key="headerItem.label"
          v-for="headerItem in headerList"
          class="header_menu_item"
          @mouseover="headerItem.label === 'ABOUT' && mouseOnHeaderItem(), headerItem.label !== 'ABOUT' && mouseLeaveHeaderItem()"
          >
          {{ headerItem.label }}<div class="header_menu_item_border"></div>
        </a>
      </div>
      <div class="header_lang_wrap">
        <a
          v-for="langItem in langList"
          :key="langItem.label"
          :href="langItem.url"
          class="header_lang_link"
          >
          <div :class="{
            'header_lang_item': true,
            'header_lang_item_active': langItem.currentLang
            }">
            {{ langItem.label }}
          </div>
        </a>
      </div>
      <div
        @click="searchWrapActive = !searchWrapActive"
        class="header_search_icon_wrap"
        >
        <img v-if="!searchWrapActive" :src="images.searchIconPC" />
        <img v-else src="../../assets/images/common/close.png">
      </div>
    </div>
    <div
      :class="{'header_search_wrap': true, 'header_search_active': searchWrapActive}"
      >
      <div class="header_search_inner_wrap">
        <div class="header_search_input">
          <input type="text" :placeholder="texts.searchPlaceHolder" v-model="searchWord" @keydown.enter="showSearchPage">
          <img src="../../assets/images/common/search.png">
        </div>
        <div class="header_search_button" @click="showSearchPage(null)">SEARCH</div>
        <div class="header_search_button_sp" @click="searchWrapActive = false">
          <img src="../../assets/images/common/close.png">
          <span>{{ texts.searchClose }}</span>
        </div>
      </div>
    </div>
    <div
      :class="{'header_bottom_wrap': true, 'header_bottom_active': headerBottomActive}"
      @mouseleave="mouseLeaveHeaderItem()"
      >
      <div class="header_bottom_inner_wrap">
        <div class="header_bottom_title_wrap">
          <div class="header_bottom_title">
            {{ headerBottomItem.label }}
          </div>
          <div class="header_bottom_subtitle">
            {{ headerBottomItem.label_sub_draw }}
          </div>
        </div>
        <div class="header_bottom_link_wrap">
          <a
            :href="page.url"
            class="header_bottom_link_item"
            :key="page.label"
            v-for="page in headerBottomItem.pages">
            <div class="header_bottom_link_title">{{ page.label }}</div>
            <div class="header_bottom_link_arrow">
              <img src="../../assets/images/common/arrow_45deg_zl.png">
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="header_inner_wrap_right_sp">
      <div
        @click="searchWrapActive = !searchWrapActive"
        class="header_search_icon_wrap"
        >
        <div class="header_search_icon_item" v-if="!searchWrapActive" :style="{backgroundImage: 'url(' + images.searchIconSP + ')'}" />
      </div>
      <div :class="{
                   'header_menu_btn': true,
                   'active': menuActive
                   }"
           id="headerMenuBtn"
           @click="toggleSpMenu()">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div
      v-if="menuActive"
      class="header_menu_sp_wrap">
      <div class="header_menu_sp_wrap_border"></div>
      <div class="header_menu_sp_link_wrap">
        <div
          class="header_menu_sp_link_item_wrap"
          v-for="headerItem in headerList.filter((item) => {return item.sp === true})"
          :key="headerItem.label"
          >
          <a
            :href="headerItem.url"
            :class="{
                     'header_menu_sp_link_item': true,
                     'no-border': headerItem.pages.length
                     }"
            >
            {{ headerItem.label }}
            <div class="header_menu_sp_link_item_arrow"></div>
          </a>
          <a
            :href="headerItemPage.url"
            class="header_menu_sp_link_sub_item"
            v-for="headerItemPage in headerItem.pages"
            :key="headerItemPage.label"
            >
            <div></div>{{ headerItemPage.label }}
          </a>
        </div>
      </div>
      <div class="header_links_logo_wrap">
        <a href="links.top"><div class="header_logo"></div></a>
        <div class="header_links_logo_right">Copyright © J-STAR Co.Ltd,All Right Reserved.</div>
      </div>
      <div class="header_copy_lang_wrap">
        <div
          v-for="langItem in langList"
          :key="langItem.label"
          :class="{
            'header_copy_lang_item': true,
            'header_copy_lang_item_active': langItem.currentLang}">
          <a :href="langItem.url">{{ langItem.labelFull }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Header',
  data() {
    return {
      headerBottomActive: false,
      searchWrapActive: false,
      searchWord: '',
      menuActive: false,
      langList: [
        {label: 'JP', labelFull: 'JAPANESE', url: '', currentLang: true},
        {label: 'EN', labelFull: 'ENGLISH', url: '', currentLang: false}
      ],
      headerList: [
        {label: 'TOP', url: '', pages: []},
        {label: 'NEWS', url: '', pages: []},
        {label: 'ABOUT', label_sub_draw: 'J-STARとは', url: '', pages: [
          {label: 'J-STARの投資とは', url: ''},
          {label: '課題解決', url: ''},
          {label: 'ESGへの配慮', url: ''},
          {label: '会社概要', url: ''}
        ]},
        {label: 'PORTFOLIO', url: '', pages: []},
        {label: 'TEAM', url: '', pages: []},
      ],
      headerBottomItem: {},
      lang: 2,
      texts: {
        searchPlaceHolder: "キーワードをご入力ください",
        searchClose: "閉じる",
        contactsEntry: "採用情報はこちら",
        contactsContact: "お問い合わせはこちら"
      },
      textsEn: {
        searchPlaceHolder: "Enter a keyword.",
        searchClose: "Close",
        contactsEntry: "Recruit",
        contactsContact: "CONTACT US"
      },
      links: {
        top: "",
        search: "",
        contactsEntry: "",
        contactsContact: ""
      },
      images: {
        searchIconPC: '',
        searchIconSP: ''
      }
    }
  },
  async mounted() {
    let element = document.getElementById('header');
    this.langList = JSON.parse(element.dataset.langList)
    this.headerList = JSON.parse(element.dataset.headerList)
    this.links = JSON.parse(element.dataset.links)
    this.images = JSON.parse(element.dataset.images)
    this.headerBottomItem = this.headerList.find((item) => item.label === 'ABOUT')
    if(!this.langList[0]['currentLang']) {
      this.texts = this.textsEn
      this.lang = 4
    }
    this.$nextTick(function () {
      if (this.langList[0]['url'].indexOf('portfolio') > -1) {
        const headerLangLink = document.getElementsByClassName('header_lang_link');
        for(let i=0;i<headerLangLink.length;i++){
          const href = headerLangLink[i].getAttribute('href');
          if (location.hash.substr(1) === 'casestudy' && href.indexOf('#casestudy') < 0) {
            console.log(href)

            headerLangLink[i].setAttribute('href', href + '#casestudy');
          } else {
            console.log(href.replace("#casestudy", ""))

            headerLangLink[i].setAttribute('href', href.replace("#casestudy", ""));
          }
        }
      }
    })

    window.addEventListener('hashchange', function () {
      const headerLangLink = document.getElementsByClassName('header_lang_link');
      for(let i=0;i<headerLangLink.length;i++){
        const href = headerLangLink[i].getAttribute('href');
        if (location.hash.substr(1) === 'casestudy' && href.indexOf('#casestudy') < 0) {
          headerLangLink[i].setAttribute('href', href + '#casestudy');
        } else {
          headerLangLink[i].setAttribute('href', href.replace("#casestudy", ""));
        }
      }
    });
  },
  computed: {
  },
  methods: {
    mouseLeaveHeaderItem() {
      this.headerBottomActive = false
    },
    mouseOnHeaderItem() {
      this.headerBottomActive = true
    },
    showSearchPage(e) {
      if(e === null || e.keyCode === 13) {
        window.location.href = this.links.search + '?q=' + this.searchWord
      }
    },
    toggleSpMenu() {
      let bodyEl = document.getElementsByTagName('body')[0]
      if(this.menuActive) {
        this.menuActive = false
        bodyEl.classList.remove("body_fixed")
      } else {
        this.menuActive = true
        bodyEl.classList.add("body_fixed")
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/global.scss';
.header_inner_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $header_height;
  margin: 0 auto;
  width: 1200px;
  max-width: 95%;
  z-index: 3;
  position: relative;
  @include sp() {
  }
  .header_inner_wrap_right {
    display: flex;
    align-items: center;
    height: $header_height;
    justify-content: space-between;
    @include sp() {
      display: none;
    }
  }
  .header_inner_wrap_right_sp {
    display: none;
    @include sp() {
      display: flex;
      justify-content: space-between;
      width: 60px;
    }
  }
}
.header_logo {
  height: 28px;
  width: 130px;
  background-size: contain;
  background-image: url('../../assets/images/header/logo_pc.png');
  @include sp() {
    position: relative;
    z-index: 4;
  }
}
.header_menu {
  &_wrap {
    font: normal normal 600 16px/20px Cormorant Garamond;
    display: flex;
    justify-content: space-between;
  }
  &_item {
    display: block;
    height: $header_height;
    line-height: $header_height;
    color: $black;
    margin: 0 20px;
    position: relative;
    &_border {
      height: 2px;
      max-width: 100%;
      position: absolute;
      bottom: 20px;
      background-color: $header_border;
    }
    &:hover {
      .header_menu_item_border {
        width: 100%;
        animation-name: menuBorderIn;
        animation-duration: 0.5s;
        animation-timing-function: ease;
      }
    }
  }
}
.header_lang {
  &_wrap {
    display: flex;
    justify-content: space-between;
  }
  &_item {
    display: block;
    height: 24px;
    width: 48px;
    font: normal normal 600 13px/16px Cormorant Garamond;
    color: $black;
    line-height: 24px;
    text-align: center;
    border-radius: 200px;
    opacity: 0.4;
    &:hover {
      opacity: 1;
    }
    &_active {
      opacity: 1;
      color: $white;
      background: transparent linear-gradient(239deg, #3B7CA5 0%, #3122A5 100%) 0% 0% no-repeat padding-box;
    }
  }
}
@keyframes menuBorderIn {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
.header_menu_btn {
  position: relative;
  width: 24px;
  height: 20px;
  cursor: pointer;
  @include sp() {
    z-index: 4;
  }
}
.header_menu_btn span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: $black;
}
.header_menu_btn, .header_menu_btn span {
  display: inline-block;
  transition: all .5s;
  box-sizing: border-box;
}
.header_menu_btn span:nth-of-type(1) {
  top: 0;
}
.header_menu_btn span:nth-of-type(2) {
  top: 9px;
}
.header_menu_btn span:nth-of-type(3) {
  bottom: 0;
}
#headerMenuBtn.active {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
#headerMenuBtn.active span:nth-of-type(1) {
  -webkit-transform: translateY(9px) rotate(-45deg);
  transform: translateY(9px) rotate(-45deg);
}
#headerMenuBtn.active span:nth-of-type(2) {
  -webkit-transform: translateY(0) rotate(45deg);
  transform: translateY(0) rotate(45deg);
}
#headerMenuBtn.active span:nth-of-type(3) {
  opacity: 0;
}
.header_menu_sp {
  &_wrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    z-index: 1;
    background-color: $white;
    padding-top: 100px;
    &_border {
      position: fixed;
      height: 100%;
      width: 4px;
      top: 0;
      left: 0;
      background: transparent linear-gradient(180deg, #3B7CA5 0%, #3122A5 100%) 0% 0% no-repeat padding-box;
      opacity: 0.8;
    }
  }
  &_link {
    &_wrap {
      width: 90%;
      margin: 0 auto;
    }
    &_item {
      padding: 0 12px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $gray;
      align-items: center;
      height: 50px;
      font: normal normal 600 22px/26px Cormorant Garamond;
      &.no-border {
        border-bottom: 0px;
      }
      &_arrow {
        height: 6px;
        width: 27px;
        background-size: cover;
        background-image: url('../../assets/images/header/menu_icon_sp.png');
      }
    }
  }
  &_link_sub_item {
    display: flex;
    align-items: center;
    height: 50px;
    font: normal normal normal 18px/26px Noto Sans JP;
    padding-left: 12px;
    div {
      height: 1px;
      width: 12px;
      background-color: $black;
      margin-right: 10px;
    }
  }
}
.header_links_contacts {
  &_wrap {
    width: 280px;
    display: block;
    margin: 16px auto 0;
  }
  &_title {
    width: 100%;
    margin-bottom: 11px;
    font: normal normal 500 14px/26px Noto Sans JP;
  }
}
.header_links_logo_wrap {
  width: 90%;
  margin: 40px auto 0;
  font: normal normal normal 10px/15px Noto Sans JP;
  color: $gray;
}
.header_copy_lang {
  &_wrap {
    height: 37px;
    display: flex;
    width: 100%;
    margin-top: 15px;
    border-top: 1px solid $gray;
  }
  &_item {
    height: 37px;
    display: block;
    text-align: center;
    font: normal normal 500 16px/37px Noto Sans JP;
    width: 50%;
    background-color: $black;
    color: $white;
    &_active {
      background-color: $white;
      color: $black;
    }
  }
}
.header_bottom {
  &_wrap {
    position: absolute;
    background: #F4F3F9 0% 0% no-repeat padding-box;
    padding: 0 74px;
    top: 100%;
    right: 100px;
    height: 0;
    overflow: hidden;
    transition: .3s;
  }
  &_active {
    height: unset;
    padding: 46px 74px;
  }
  &_inner_wrap {
    display: flex;
  }
  &_title {
    font: normal normal bold 32px/39px Cormorant Garamond;
    letter-spacing: 1.6px;
    color: #22154E;
  }
  &_subtitle {
    font: normal normal normal 14px/20px Noto Sans JP;
    letter-spacing: 0px;
    color: #22154E;
  }
  &_link {
    &_wrap {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 480px;
      margin: 0 54px;
    }
    &_item {
      width: 220px;
      border-bottom: 1px solid #EAEAEA;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 42px;
    }
    &_title {
      color: var(--black｜-212121);
      padding-left: 14px;
      font: normal normal normal 13px/13px Noto Sans JP;
      color: #212121;
    }
    &_arrow {
      img {
        width: 17px;
        padding-bottom: 3px;
        margin-right: 14px;
        transition: .3s;
      }
    }
    &_item:hover {
      .header_bottom_link_arrow img {
        margin-right: 4px;
      }
    }
  }
}
.header_search {
  &_wrap {
    padding: 0 0;
    height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 100%;
    background: transparent linear-gradient(258deg, rgba(53,168,224,.3) 0%, rgba(47, 154, 214, .3) 10%, rgba(34, 123, 191, .3) 38%, rgba(29, 112, 183, .3) 54%, rgba(30, 104, 177, .3) 64%, rgba(35, 85, 161, .3) 79%, rgba(43, 53, 135, .3) 96%, rgba(45, 46, 130, .3) 100%) 0% 0% no-repeat padding-box;
    width: 100vw;
    left: calc(50% - 50vw);
    transition: .3s;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    @include sp() {
      top: 64px;
      z-index: 1002;
    }
  }
  &_active {
    padding: 25px 0;
    height: unset;
  }
  &_inner_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &_input  {
    display: block;
    height: 56px;
    padding: 0;
    background-color: white;
    border: none;
    position: relative;
    input {
      font: normal normal normal 16px/56px Noto Sans JP;
      box-sizing: border-box;
      width: 760px;
      border: none;
      padding: 0 0 0 57px;
      outline: none;
      &::placeholder {
        color: #B9B9B9;
      }
      @include sp() {
        width: 294px;
      }
    }
    img {
      position: absolute;
      top: 18px;
      left: 24px;
      height: 20px;
      width: 21px;
      color: #aaaaaa;
    }
  }
  &_button  {
    height: 56px;
    width: 120px;
    text-align: center;
    font: normal normal 600 16px/56px Cormorant Garamond;
    color: white;
    background-color: #212121;
    @include sp() {
      display: none;
    }
  }
  &_button_sp  {
    display: none;
    margin-left: 14px;
    @include sp() {
      display: block;
    }
    img {
      display: block;
      width: 17px;
      margin: 0 auto 6px;
    }
    span {
      display: block;
      font: normal normal normal 12px/22px Noto Sans JP;
      color: #212121;
      margin: 0 auto;
    }
  }
}
.header_search_icon {
  &_wrap {
    img {
      object-fit: contain;
      width: 19px;
      margin-left: 4px;
      padding-top: 4px;
      @include sp() {
        width: 21px;
      }
    }
  }
  &_item {
    width: 19px;
    height: 19px;
    background-size: contain;
    background-repeat: no-repeat;
    @include sp() {
      width: 21px;
      height: 21px;
    }
  }
}
</style>
