<template>
  <div class="team_member_list_contents_wrap">
    <div class="team_member_list_inner_wrap">
      <div
        v-for="item in getTeamMemberList()"
        :key="item.idLabel"
        class="team_member_list_item"
        >
        <a :href="item.link">
          <div class="team_member_list_item_image_wrap">
            <div class="team_member_list_item_image" :style="{backgroundImage: 'url(' + item.thumbnail_url + ')' }"></div>
            <div class="team_member_list_item_image_readmore">READ MORE</div>
          </div>
          <div class="team_member_list_item_text_wrap">
            <div class="team_member_list_item_title">
              {{ item.title.rendered }}
              <span class="team_member_list_item_sub_title">
                {{ item.team_sub_name }}
              </span>
            </div>
            <div class="team_member_list_item_group" v-if="item.team_group !== 'none'">
              {{ item.team_group }}
            </div>
            <div class="team_member_list_item_group" v-if="item.team_group2 !== 'none'">
              {{ item.team_group2 }}
            </div>
            <div class="team_member_list_item_desc">
              {{ item.team_position }}
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { getWpResponseAll } from '@/modules/utils.js'
export default {
  name: 'TeamMemberList',
  data() {
    return {
      groupList: [
        {label: 'Management Member', name: 'ManagementMember'},
        {label: 'Investment Group Investment', name: 'InvestmentGroupInvestment'},
        {label: 'Investment Group Value Creation', name: 'InvestmentGroupValueCreation'},
        {label: 'Investor Relations', name: 'InvestorRelations'}
      ],
      teamMemberList: [],
      teamGroupList: [],
      lang: 2
    }
  },
  async mounted() {
    this.lang = document.getElementById('teamMemberList').dataset.lang;
    this.teamMemberList = await getWpResponseAll('/wp-json/wp/v2/team', {language: this.lang})
    this.teamGroupList = this.teamMemberList.map((item) => {
      return item.team_group
    })
    this.teamGroupList = Array.from(new Set(this.teamGroupList))
    this.teamGroupList = this.teamGroupList.map((item) => {
      return {label: item, id: '#' + item.replace(/ /g, '_'), idLabel: item.replace(/ /g, '_')}
    })
  },
  computed: {
  },
  methods: {
    getTeamMemberList() {
      return this.teamMemberList.sort(
        (a, b) => {
          if(a.team_sort_name >= b.team_sort_name) return 1
          else return -1
        }
      )
    },
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
$dk_blue: #0D1F6A;
$black: #212121;
$gray: #EAEAEA;
$gray_light_1: #B9B9B9;
* {
  box-sizing: border-box;
}
.team_member_list {
  &_inner_wrap {
    width: 1000px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    @media screen and (max-width: 1000px) {
      width: 90%;
    }
  }
  &_item {
    width: 25%;
    margin-bottom: 40px;
    animation: fadeIn .5s ease 00s 1 normal;
    @media screen and (max-width: 1000px) {
      width: 100%;
      a {
        display: flex;
        justify-content: left;
        align-items: center;
      }
    }
    &_image {
      &_wrap {
        width: 212px;
        height: 318px;
        border: 1px solid #EAEAEA;
        display: flex;
        align-items: end;
        justify-content: right;
        position: relative;
        @media screen and (max-width: 1000px) {
          width: 100px;
          height: 140px;
          margin-right: 32px;
        }
      }
      width: 212px;
      height: 318px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      @media screen and (max-width: 1000px) {
        width: 100px;
        height: 140px;
      }
      &_readmore {
        color: white;
        padding: 0 23px 20px 0;
        font-size: 12px;
        position: absolute;
        @media screen and (max-width: 1000px) {
          display: none;
        }
      }
    }
    &_text_wrap {
      max-width: 100%;
      @media screen and (max-width: 1000px) {
        max-width: calc(100% - 140px - 32px);
      }
    }
    &_title {
      font: normal normal bold 20px/20px Noto Sans JP;
      padding-top: 30px;
      color: $black;
      white-space:pre-wrap;
      @media screen and (max-width: 1000px) {
        padding-top: 0;
        max-width: 100%;
        font: normal normal bold 18px/26px Noto Sans JP;
      }
    }
    &_sub_title {
      font: normal normal normal 14px/20px Noto Sans JP;
      color: $gray_light_1;
      white-space:pre-wrap;
      display: inline-block;
      @media screen and (max-width: 1000px) {
        display: inline-block;
        width: 100%;
      }
    }
    &_group {
      font: normal 500 medium 14px/32px Noto Sans JP;
      letter-spacing: 0px;
      color: #0D1F6A;
    }
    &_desc {
      font: normal normal 500 13px/19px Noto Sans JP;
      white-space: pre-wrap;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
</style>
