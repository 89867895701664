<template>
  <div class="pagination_root">
    <div
      :class="{
              'pagination_item': true,
              'disable': current === 1
      }"
      @click="clickItem(current - 1,  current !== 1)"
      >
      <img src="../../assets/images/common/arrow_rev.png">
    </div>
    <div
      v-for="item in getValidNumbers"
      :key="item.num"
      :class="{
              'pagination_item': true,
              'active': item.num === current
      }"
      @click="clickItem(item.num, item.valid)"
      >
      <span>
        {{ item.num }}
      </span>
    </div>
    <div
      :class="{
              'pagination_item': true,
              'disable': current === getNumbers.length
      }"
      @click="clickItem(current + 1,  current !== getNumbers.length)"
      >
      <img src="../../assets/images/common/arrow.png">
    </div>
  </div>
</template>
<script>
export default {
  name: 'PaginationList',
  props: {
    current: {
      type: Number,
      default: 1
    },
    maximum: {
      type: Number,
      default: 1
    }
  },
  async mounted() {
  },
  computed: {
    getNumbers() {
      return Array.from(Array(this.maximum), (v, k) => {
        let num = k + 1
        return {
          num: num,
          valid: (num === 3  && this.current === 1) || (this.current - 1 <= num && num <= this.current + 1) || (num === this.maximum - 2 && this.current === this.maximum)
        }
      })
    },
    getValidNumbers() {
      return this.getNumbers.filter((item) => {
        return item.valid
      })
    }
  },
  methods: {
    clickItem(num, valid) {
      if(!valid) return
      this.$emit('clickItem', num)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/global.scss';
.pagination {
  &_root {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
    width: 100%;
    @include sp() {
      justify-content: center;
    }
  }
  &_item {
    width: 48px;
    height: 48px;
    border: 1px solid #212121;
    color: #212121;
    display: flex;
    margin-left: 12px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    &:hover {
      background-color: rgba(0, 0, 0, .1);
    }
    &.active {
      color: #ffffff;
      background-color: #21144D;
    }
    &.disable {
      opacity: .3;
    }
  }
}
</style>
