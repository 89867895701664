import axios from 'axios'
export const shuffle = ([...array]) => {
  for (let i = array.length - 1; i >= 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
export const getWpResponseAll = async (url, params) => {
  let responseData = []
  let response = await axios.get(url, {
    params: {
      ...params,
      per_page: 100,
      page: 1
    }
  })
  let requestCount = Number(response.headers['x-wp-totalpages'])
  responseData = response.data
  for (let i = 1; i < requestCount; i++) {
    response = await axios.get('/wp-json/wp/v2/team', {
      params: {
        ...params,
        per_page: 100,
        page: i + 1
      }
    })
    responseData = responseData.concat(response.data)
  }
  return responseData
}
export const getLangSlugByLangNum = (langNum) => {
  let langStr = 'ja'
  if (langNum != 2) {
    langStr = 'en'
  }
  return langStr
}
