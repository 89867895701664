<template>
  <div>
    <div class="portfolio_tab_wrap">
      <div
        :class="{'portfolio_tab_item': true, 'active': option.category == categories.portfolio.id}"
        @click="option.category = categories.portfolio.id; categorySelect('portfolio')">
        PORTFOLIO ALL<span>{{ keyFilter(portfolioData, categories.portfolio.id, 'categories').length }}</span></div>
      <div
        :class="{'portfolio_tab_item': true, 'active': option.category == categories.casestudy.id}"
        @click="option.category = categories.casestudy.id; categorySelect('casestudy')">
        CASESTUDY ALL<span>{{ keyFilter(portfolioData, categories.casestudy.id, 'categories').length }}</span></div>
    </div>
    <div class="portfolio_search_open_button" @click="toggleSpMenu()">
      {{ texts.searchLabel }}
    </div>
    <div class="portfolio_contents_wrap">
      <div :class="{'active': search_modal_active, 'portfolio_search_wrap': true}">
        <div class="portfolio_search_close_button_wrap" @click="toggleSpMenu()">
          <div class="portfolio_search_close_button_item">
            <span></span>
            <span></span>
          </div>
        </div>
        <div class="portfolio_search_inner_wrap">
          <div
            :class="{'portfolio_search_able': !optionMasterItem.active}"
            v-for="optionMasterItem in optionMaster"
            :key="optionMasterItem.title"
            >
            <h3 @click="optionMasterItem.active = !optionMasterItem.active">{{ optionMasterItem.title }}<span class="option_arrow only_pc"></span></h3>
            <div class="portfolio_search_item_wrap">
              <div v-for="(item, i) in optionMasterItem.list" :key="i" class="portfolio_search_item">
                <input
                  :id="item.value"
                  type="checkbox"
                  :value="item.value"
                  v-model="option[optionMasterItem.name]"
                >
                <label :for="item.value" class="portfolio_search_item_label">{{ item.label }}</label>
              </div>
            </div>
          </div>
          <div class="portfolio_search_button_white portfolio_search_button only_sp" @click="resetOptions()">
            CLEAR ALL
          </div>
          <div class="portfolio_search_button_blue portfolio_search_button only_sp" @click="toggleSpMenu()">
            {{ texts.searchSubmit }}
          </div>
          <div class="portfolio_search_crearall only_pc" @click="resetOptions()">
            CLEAR ALL
          </div>
        </div>
      </div>
      <div class="portfolio_list_wrap">
        <div v-for="item in filterdList" :key="item.id" :class="{'portfolio_list_item': true, 'casestudy_list_item': option.category === categories.casestudy.id}">
          <a :href="item.link" v-if="option.category === categories.portfolio.id">
            <div class="portfolio_list_item_image_wrap">
              <div v-if="item.thumbnail_url.length > 0" class="portfolio_list_item_image" :style="{backgroundImage: 'url(' + item.thumbnail_url + ')' }"></div>
              <div v-else class="portfolio_list_item_image portfolio_list_item_image_noimage"></div>
            </div>
            <div class="portfolio_list_item_title">
              {{ item.title.rendered }}
            </div>
          </a>
          <a :href="item.link" v-else>
            <div class="casestudy_list_item_wrap">
              <div class="casestudy_list_item_image_wrap">
                <div v-if="item.thumbnail_url.length > 0" class="casestudy_list_item_image" :style="{backgroundImage: 'url(' + item.thumbnail_url + ')' }"></div>
                <div v-else class="casestudy_list_item_image casestudy_list_item_image_noimage"></div>
              </div>
              <div class="casestudy_list_item_content_wrap">
                <div class="casestudy_list_item_title">
                  {{ item.title.rendered }}
                </div>
                <div class="casestudy_list_item_subtitle">
                  {{ item.portfolio_subtitle }}
                </div>
                <div class="casestudy_list_item_desc">
                  {{ item.portfolio_description }}
                </div>
              </div>
            </div>
          </a>
        </div>
        <div v-if="filterdList.length == 0 && !loading" class="portfolio_list_notfound" v-html="texts.noResults" />
      </div>
    </div>
  </div>
</template>
<script>
import {getWpResponseAll, getLangSlugByLangNum} from '@/modules/utils.js'
import axios from 'axios'
export default {
  name: 'HelloWorld',
  data() {
    return {
      loading: true,
      search_modal_active: false,
      portfolioCount: 18,
      optionMaster: [],
      option: {
        category: 1,
        status: [],
        type: [],
        industry: [],
        year: [],
      },
      categories: {
        portfolio: {
          id: 0,
          slug: '',
          name: 'PORTFOLIO ALL'
        },
        casestudy: {
          id: 0,
          slug: '',
          name: 'CASE STUDY ALL'
        }
      },
      portfolioData: [],
      texts: {
        searchLabel: "条件を絞り込む",
        searchSubmit: "この条件で検索する",
        noResults: "検索結果がありません。 <br> 恐れ入りますが、選択している条件を減らしてみてください。"
      },
      textsEn: {
        searchLabel: "Refined search",
        searchSubmit: "Search by this condition",
        noResults: "There is no search result.<br>Sorry, but try reducing the conditions you have selected."
      },
      lang: 2
    }
  },
  async mounted() {
    let element = document.getElementById('portfolioList');
    this.optionMaster = JSON.parse(element.dataset.optionMaster)
    this.lang = element.dataset.lang
    let langStr = getLangSlugByLangNum(element.dataset.lang)
    if (langStr != 'ja') {
      this.texts = this.textsEn
    }
    const categoryResponse = await axios.get('/wp-json/wp/v2/categories?slug=' + element.dataset.categoryslug + '&lang=' + langStr)
    this.categories.portfolio.id = categoryResponse.data.find(item => item.name == this.categories.portfolio.name).id
    this.categories.casestudy.id = categoryResponse.data.find(item => item.name == this.categories.casestudy.name).id
    this.categories.portfolio.slug = categoryResponse.data.find(item => item.name == this.categories.portfolio.name).slug
    this.categories.casestudy.slug = categoryResponse.data.find(item => item.name == this.categories.casestudy.name).slug
    if (location.hash.substr(1) === 'casestudy') {
      this.option.category = this.categories.casestudy.id
    } else {
      this.option.category = this.categories.portfolio.id
    }
    const categoryIdList = categoryResponse.data.map(x => x.id).reduce((acc, cur) => acc + '+' + cur)
    this.portfolioData = await getWpResponseAll('/wp-json/wp/v2/portfolio?categories=' + categoryIdList, {language: this.lang})
    let minYear = 2006
    let currentTime = new Date()
    let maxYear = currentTime.getFullYear()
    let yearOption
    for(let i = maxYear; i >= minYear; i--) {
      yearOption = this.optionMaster.find(item => item.name == 'year')
      if (yearOption && this.lang == 2) {
        yearOption.list.push({label: i + '年', value: i})
      } else if (yearOption) {
        yearOption.list.push({label: i + '', value: i})
      }
    }
    this.loading = false



  },
  computed: {
    filterdList() {
      let list = this.portfolioData
      list = this.keyFilter(list, this.option.category, 'categories')
      list = this.optionFilter(list, this.option.status, 'portfolio_status')
      list = this.optionFilter(list, this.option.type, 'portfolio_type')
      list = this.optionFilterForArray(list, this.option.industry, 'portfolio_industry')
      list = this.dateFilter(list, this.option.year, 'portfolio_date')
      if(list.length/3 < 3 && list.length != 0){
        document.getElementsByClassName("portfolio_list_wrap")[0].classList.add("height-min"); 
      }else if(list.length/3 > 3 && list.length != 0){
        document.getElementsByClassName("portfolio_list_wrap")[0].classList.remove("height-min");
      }
      return list.sort(
        (a, b) => {
          if(a.portfolio_date < b.portfolio_date) return 1
          else return -1
        }
      )
    },
  },
  methods: {
    dateFilter(list, option, optionKey) {
      let year
      if (option.length == 0) return list
      else
        return list.filter((item) => {
          year = Number(item[optionKey].split('/')[0])
          return option.indexOf(year) >= 0
        })
    },
    optionFilter(list, option, optionKey) {
      if (option.length == 0) return list
      else
        return list.filter((item) => {
          return option.indexOf(item[optionKey]) >= 0
        })
    },
    optionFilterForArray(list, option, optionKey) {
      if (option.length == 0) return list
      else
        return list.filter((item) => {
          return [...item[optionKey], ...option].filter(innerItem => item[optionKey].includes(innerItem) && option.includes(innerItem)).length > 0
        })
    },
    keyFilter(list, option, listKey) {
      if (option == 0) return list
      else
        return list.filter((item) => {
          if (Object.keys(item).indexOf(listKey) >= 0 && item[listKey].length > 0) {
            return item[listKey].indexOf(option) >= 0
          } else
            return list
        })
    },
    keyValidate(item, option, listKey) {
      return item[listKey].indexOf(option) >= 0
    },
    resetOptions() {
      this.option.status = []
      this.option.type = []
      this.option.industry = []
      this.option.year = []
    },
    toggleSpMenu() {
      let bodyEl = document.getElementsByTagName('body')[0]
      if(this.search_modal_active) {
        this.search_modal_active = false
        bodyEl.classList.remove("body_fixed")
      } else {
        this.search_modal_active = true
        bodyEl.classList.add("body_fixed")
      }
    },
    categorySelect($category) {
      if ($category === 'portfolio') {
        location.hash = 'portfolio'
      } else if ($category === 'casestudy') {
        location.hash = 'casestudy'
      }
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/global.scss';
$dk_blue: #0D1F6A;
$black: #212121;
* {
  box-sizing: border-box;
}
.portfolio_tab {
  &_wrap {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    width: 1000px;
    max-width: 100%;
    cursor: pointer;
  }
  &_item {
    height: 100px;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $dk_blue;
    font: normal normal 600 24px/35px Cormorant Garamond;
    border: 1px solid #B9B9B9;
    opacity: .19;
    &.active {
      opacity: 1;
      border: 0;
      background: transparent linear-gradient(80deg, rgba(209, 192, 214, .19) 0%, rgba(155, 184, 244, .19) 100%) 0% 0% no-repeat padding-box;
    }
    span {
      font: normal normal 600 11px/25px Noto Serif JP;
      padding: 0 0 10px 10px;
    }
    @include sp() {
      font: normal normal 600 17px/22px Cormorant Garamond;
      flex-direction: column;
      span {
        font: normal normal 600 11px/25px Noto Serif JP;
        padding: 0 0 0 0;
      }
    }
  }
}
.portfolio_contents {
  &_wrap {
    margin: 0 auto 120px;
    padding-top: 80px;
    display: flex;
    justify-content: space-between;
    width: 1000px;
    max-width: 100%;
    @include sp() {
      margin: 0 auto 100px;
      width: 100%;;
      justify-content: center;
    }
  }
}
.portfolio_search {
  &_wrap {
    width: 170px;
    padding: 10px 0;
    @include sp() {
      overflow-y: scroll;
      height: 100vh;
    }
    h3 {
      cursor: pointer;
      padding: 0 0 0 7px;
      border-left: 3px solid $dk_blue;
      font: normal normal normal 14px/20px Noto Sans JP;
      margin: 15px 0 15px -10px;
      @include sp() {
        margin: 20px 0 15px 7px;
      }
    }
    @include sp() {
      display: none;
      position: fixed;
      width: 100%;
      z-index: 2001;
      top: 0;
      left: 0;
      background-color: rgba(0,0,0,.5);
      min-height: 100vh;
      &.active {
        display: block;
      }
    }
  }
  &_inner_wrap {
    @include sp() {
      overflow-y: scroll;
      background-color: #fff;
      padding: 10px 20px;
    }
  }
  &_item_wrap {
    @include sp() {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &_close_button {
    &_wrap {
      display: none;
      @include sp() {
        height: 80px;
        display: block;
        position: relative;
        padding: 40px 20px;
      }
    }
    &_item {
      height: 40px;
      width: 40px;
      position: absolute;
      right: 20px;
      span {
        width: 30px;
        height: 2px;
        display: block;
        background-color: white;
        position: absolute;
        right: 0;
        &:nth-child(1) {
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          transform: rotate(-45deg);
        }
      }
    }
  }
  &_open_button {
    display: none;
    @include sp() {
      background-color: $dk_blue;
      height: 46px;
      width: 100%;
      font: normal normal normal 14px/20px Noto Sans JP;
      color: #fff;
      display: block;
      line-height: 46px;
      text-align: center;
    }
  }
  &_item_label {
    cursor: pointer;
    display: inline-block;
    padding: 5px 30px;
    position: relative;
    width: auto;
    font: normal normal normal 13px/19px Noto Sans JP;
    &::before {
      background: #fff;
      border: 1px solid $gray;
      border-radius: 2px;
      content: '';
      display: block;
      height: 16px;
      left: 5px;
      margin-top: -10px;
      position: absolute;
      top: 50%;
      width: 16px;
    }
    &::after {
      border-right: 2px solid #fff;
      border-bottom: 2px solid #fff;
      border-radius: 1px;
      content: '';
      display: block;
      height: 9px;
      left: 10px;
      margin-top: -8px;
      opacity: 1;
      position: absolute;
      top: 50%;
      transform: rotate(45deg);
      width: 5px;
    }
  }
}
@include pc() {
  .portfolio_search_able {
    opacity: .6;
    color: $black_sub;
    h3 {
      border-left: 3px solid #fff;
    }
    .portfolio_search_item {
      display: none;
    }
  }
}
input[type=checkbox] {
  display: none;
  &:checked {
    & + .portfolio_search_item_label::before {
      background: $dk_blue;
    }
    & + .portfolio_search_item_label::after {
      opacity: 1;
    }
  }
}
.portfolio_list {
  &_wrap {
    display: flex;
    justify-content: left;
    width: 800px;
    max-width: 100%;;
    flex-wrap: wrap;
    @include sp() {
      width: 100%;;
      justify-content: center;
    }
  }
  &_item {
    width: 212px;
    margin: 0 18px 40px 18px;
    animation: fadeIn .5s ease 00s 1 normal;
    @include sp() {
      width: 90%;
      a {
        display: flex;
        justify-content: left;
        align-items: center;
      }
    }
    &_image {
      &_wrap {
        width: 212px;
        height: 200px;
        border: 1px solid #EAEAEA;
        @include sp() {
          width: 140px;
          height: 132px;
        }
      }
      &_noimage {
        background-image: url('../../assets/images/common/noimage.png');
        background-size: cover;
        background-position: center;
      }
      width: 212px;
      height: 200px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      @include sp() {
        width: 140px;
        height: 132px;
      }
    }
    &_title {
      padding-top: 8px;
      font: normal normal bold 16px/24px Noto Sans JP;
      color: $black;
      @include sp() {
        padding-left: 24px;
        width: calc(100% - 140px);
        font: normal normal bold 16px/24px Noto Sans JP;
      }
    }
  }
  &_notfound {
    width: 100%;
    padding-top: 20px;
    text-align: center;
    font: normal normal bold 16px/24px Noto Sans JP;
    color: #212121;
    padding-bottom: 120px;
    @include sp() {
      width: 90%;
      margin: 0 auto;
    }
  }
}
.casestudy_list_item {
  width: 100%;
  &_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &_content_wrap {
    width: calc(100% - 220px);
    @include sp() {
      width: calc(100% - 160px);
    }
  }
  &_image {
    &_wrap {
      width: 212px;
      height: 200px;
      border: 1px solid #EAEAEA;
      @include sp() {
        width: 140px;
        height: 160px;
      }
    }
    &_noimage {
      background-image: url('../../assets/images/common/noimage.png');
      background-size: cover;
      background-position: center;
    }
    width: 212px;
    height: 200px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    @include sp() {
      width: 140px;
      height: 160px;
    }
  }
  &_title {
    font: normal normal bold 16px/24px Noto Sans JP;
    color: $dk_blue;
  }
  &_subtitle {
    padding-top: 18px;
    font: normal normal 500 22px/32px Noto Sans JP;
    letter-spacing: 1.32px;
    color: $black;
    @include sp() {
      font: normal normal 500 16px/24px Noto Sans JP;
      letter-spacing: 0.96px;
    }
  }
  &_desc {
    padding-top: 8px;
    font: normal normal normal 16px/22px Noto Sans JP;
    letter-spacing: 0.96px;
    color: $gray_dark_2;
    @include sp() {
      font: normal normal normal 13px/20px Noto Sans JP;
      letter-spacing: 0.78px;
    }
  }
  @include sp() {
  }
}
@keyframes fadeIn {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.option_arrow {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-right: 2px solid $dk_blue;
  border-bottom: 2px solid $dk_blue;
  transform: rotate(225deg);
  margin-left: 10px;
  margin-bottom: -3px;
  transition: all .3s;
}
.portfolio_search_able {
  .option_arrow {
    transform: rotate(45deg);
    border-color: $gray;
    margin-bottom: 3px;
  }
}
.readmore {
  &_wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    &_disabled {
      margin-bottom: unset;
    }
  }
  &_item {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: transparent linear-gradient(85deg, rgba(209, 192, 214, 0.19) 0%, rgba(155, 184, 244, 0.19) 100%) 0% 0% no-repeat padding-box;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    margin-bottom: 30px;
    transition: all .3s;
    &_text {
      font: normal normal 500 14px/32px Noto Sans JP;
      color: $dk_blue;
    }
    &_arrow {
      position: absolute;
      bottom: -10px;
      width: 8px;
      height: 26px;
      background-image: url('../../assets/images/common/arrow_bottom.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      transition: all .3s;
    }
    &:hover {
      .readmore_item_arrow {
        bottom: -20px;
      }
    }
  }
}
.portfolio_search_crearall {
  margin-top: 36px;
  font: normal normal 500 14px/14px Noto Sans JP;
  letter-spacing: 0px;
  color: #21144D;
  cursor: pointer;
}
.portfolio_search_button {
  border-radius: 200px;
  width: 280px;
  height: 64px;
  margin: 24px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &_white {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #21144D;
    color: #21144D;
    margin-top: 40px;
  }
  &_blue {
    background: #21144D 0% 0% no-repeat padding-box;
    border: 1px solid #21144D;
    color: white;
    margin-bottom: 60px;
  }
}
</style>
